$primary: #fb867d;
$error: #fb7080;
$black: #000;
$white: #fff;
$main: #263238;
$neutral: #7e8087;
$neutral-2: #abadb4;
$border-line: rgba(54, 82, 129, 0.08);

$fontsize11: 0.6875rem;
$fontsize12: 0.75rem;
$fontsize13: 0.8125rem;
$fontsize14: 0.875rem;
$fontsize15: 0.9375rem;
$fontsize16: 1rem;
$fontsize18: 1.125rem;
$fontsize20: 1.25rem;
$fontsize24: 1.5rem;
