main {
  overflow: auto;
}

.payment-container {
  padding: 0px 16px;
  margin-bottom: 50px; // fixed when payment option shadow was clipped

  .no-phone-error {
    display: flex;
    justify-content: center;
    margin-top: auto;
    position: fixed;
    width: 90%;
    bottom: 90px;
    color: red;
  }
}

.total-coin {
  background: linear-gradient(180deg, #e966a3 -38.51%, #f160a4 233.78%);
  box-shadow: 0px 30px 30px -24px rgba(#e966a3, 0.5);
  border-radius: 10px;
  color: $white;
  padding: 16px;
  margin: 20px auto 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .label {
    display: flex;
    align-items: center;

    .icon-menu {
      width: 34px;
      height: 34px;
      border-radius: 100px;
      background: #ffffff;
      box-shadow: 0px 3.58056px 35.8056px rgba(184, 202, 222, 0.27);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text {
      font-size: $fontsize16;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  .value {
    display: flex;
    align-items: center;

    .text {
      font-size: $fontsize20;
      font-weight: 700;
      margin-left: 10px;
    }
  }
}

.phone-container {
  margin-bottom: 15px;

  .phone-input {
    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(31, 47, 70, 0.08);
    border-radius: 10px;
    padding: 16px;
    position: relative;

    h3 {
      margin: 0px;
      margin-bottom: 15px;
    }

    .consent {
      color: #abadb4;
      margin: 0px;
      margin-top: 10px;
      font-size: 12px;

      .checkbox {
        margin: 0px;
        p {
          margin: 0px;
          margin-top: 5px;
        }
      }
    }
  }
}

.payment-options {
  .payment-option--smartum,
  .payment-option--epassi {
    input[type='radio']:not(:checked) {
      + label {
        .option-desc,
        .option-amount {
          display: none;
        }
      }
    }
  }
  .payment-option {
    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(31, 47, 70, 0.08);
    border-radius: 10px;
    padding: 16px;
    position: relative;

    + .payment-option {
      margin-top: 16px;
    }

    input[type='radio'],
    input[type='checkbox'] {
      + label {
        display: block;
        position: static;
        color: $main;
        padding: 0;

        &::before,
        &::after {
          inset-inline-start: auto;
          top: 16px;
          inset-inline-end: 16px;
          width: 24px;
          height: 24px;
        }
      }
    }

    .option-name {
      font-size: $fontsize16;
      font-weight: 600;
      margin: 0 0 4px;
    }

    .option-desc {
      font-size: $fontsize13;
      color: $neutral-2;
      max-width: 75%;
      margin-bottom: 20px;
    }

    .option-amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $fontsize14;
      font-weight: 500;
      color: #707070;

      .option-price {
        color: $primary;
        font-size: $fontsize16;
        font-weight: 500;
      }
    }

    .get-coin {
      display: inline-flex;
      align-items: center;
      color: $white;
      background: #fb867d;
      border-radius: 7px;
      padding: 8px 10px;
      opacity: 0;

      .text {
        margin-left: 10px;
        font-size: $fontsize14;
        font-weight: 600;
      }
    }
  }
}

.payment-status {
  padding: 0px 16px;
  text-align: center;
  overflow-y: auto;
  height: calc(100vh - 150px);

  .status-circle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    max-width: 250px;
    margin: 40px auto;

    .outer-circle {
      width: 100%;
      height: 100%;
      background-color: #fb857d7d;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50% 50%;

      .mid-circle {
        width: 90%;
        height: 90%;
        background-color: #fb857d6e;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50% 50%;

        .inner-circle {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          width: 90%;
          height: 90%;
          background-color: #fb867d;
          border-radius: 50% 50%;
          font-size: 2rem;
          flex-direction: column;

          h1 {
            margin-bottom: 15px;
          }

          p {
            margin-top: 15px;
            font-size: 26px;
          }
        }
      }
    }
  }

  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    max-width: 300px;
    margin: 40px auto;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .message {
    font-size: $fontsize16;
    color: $neutral;
    font-weight: 400;
  }

  .note {
    font-size: $fontsize18;
    color: $primary;
    margin-top: 15px;
  }
}

.btn-continue {
  display: block;
  position: fixed;
  bottom: 50px;
  left: 16px;
  right: 16px;
  z-index: 10;
  background: #fb867d;
  box-shadow: 0px 9.42px 17px rgba(35, 41, 136, 0.33);
  border-radius: 10px;
  color: $white;
  font-size: $fontsize20;
  font-weight: 500;
  text-align: center;
  padding: 15px 16px;
}

.takeaway {
  padding: 2px;
  background-color: rgb(255, 219, 76);
  color: black;
  border-radius: 15px;
  text-align: center;
}

.btn-open-menu {
  display: block;
  // position: fixed;
  // bottom: 50px;
  // left: 16px;
  // right: 16px;
  z-index: 10;
  background: #fb867d;
  box-shadow: 0px 9.42px 17px rgba(35, 41, 136, 0.33);
  border-radius: 10px;
  color: $white;
  font-size: $fontsize20;
  font-weight: 500;
  text-align: center;
  padding: 15px 16px;
}

.btn-close {
  cursor: pointer;
  display: block;
  // position: fixed;
  // bottom: 50px;
  // left: 16px;
  // right: 16px;
  z-index: 10;
  color: #fb867d;
  background: transparent;
  // box-shadow: 0px 9.42px 17px rgba(35, 41, 136, 0.33);
  border-radius: 10px;
  font-size: $fontsize20;
  font-weight: 500;
  text-align: center;
  padding: 15px 16px;
}

.PhoneInputInput {
  border: 1px solid lightgray;
  padding: 10px;
}

.save-receipt {
  background: none;
  color: #fb867d;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.receipt-container {
  margin: 20px auto;
  margin-top: 40px;
  width: 80%;
  min-width: 300px;
  box-shadow: 0px 4px 40px rgba(184, 202, 222, 0.27);
  border-radius: 13px;
  padding: 20px 10px;

  .separator {
    width: 80%;
    margin: 0 auto;
    height: 1px;
    border-bottom: 1px dashed darkgray;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .top {
    text-align: center;
  }

  .bottom {
    margin-top: 20px;
    margin-bottom: 20px;

    .order-id {
      text-align: center;
      margin-bottom: 20px;
    }

    .rows {
      width: 100%;

      .row {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

// @media print
// {
//   body *:not(#receipt-print) {
//     display: none;
//   }

//   #receipt-print { display: block; }
// }
