.order-status-progress-bar-container {
  position: relative;
  width: 100%;
  border: 2px solid #3c3c3c;
  margin-top: 20px;

  .order-status-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: green;

    // animation: progress 5s linear;
  }

  .countdown {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// @keyframes progress {
//   0% {
//     width: 100;
//   }

//   40% {
//     background-color: rgb(137, 152, 0);
//   }

//   100% {
//     width: 0%;
//     background-color: rgb(191, 0, 0);
//   }
// }
