html {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: $fontsize14;
  line-height: 1.3rem;
  color: $main;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.page-layout {
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

img,
svg {
  vertical-align: top;
}

main {
}

.menu-container {
  position: relative;
  min-height: 50vh;
  background: $white;
  border-radius: 20px 20px 0 0;
  padding: 16px 16px 60px;

  .icon-menu {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ed4c5c;
    border-radius: 100px;
  }

  .description {
    max-width: 75vw;
    color: $neutral;
  }
}

.coin-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(36, 42, 137, 0.1);
  box-shadow: 0px 4px 40px rgba(184, 202, 222, 0.27);
  border-radius: 13px;
  padding: 10px 16px;
  margin-top: 16px;

  label {
    display: flex;
    align-items: center;
    color: $main;

    .text {
      font-size: $fontsize16;
      font-weight: 600;
      margin-left: 16px;
    }
  }

  .value {
    font-size: $fontsize18;
    font-weight: 700;
    color: #fb867d;
  }
}

.store-list {
  display: flex;
  padding: 16px;
  padding-top: 0;

  .language-select-flag {
    cursor: pointer;
    width: 48px;
    object-fit: contain;
    border-radius: 15px;
  }
  .language-select-fallback {
    display: flex;
    padding: 5px;
    border-radius: 5px;
    background-color: #fb867d;
  }
}

.store-item {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 16px;

  .store-img {
    width: 97px;
    height: 97px;
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .store-caption {
    .store-name {
      font-size: $fontsize18;
      font-weight: 600;
      margin: 0 0 8px;
    }

    ul.store-contact {
      margin: 0;
      color: $neutral;

      .closed {
        color: red;
        font-weight: 600;
      }

      li {
        display: flex;

        + li {
          margin-top: 4px;
        }

        .icon {
          width: 16px;
        }

        span {
          flex: 1;
          margin-left: 10px;
        }
      }
    }

    .btn-menu {
      position: absolute;
      bottom: 20px;
      right: 16px;
    }
  }
}

.separate {
  display: block;
  height: 6px;
  margin: 0 -16px;
  background: rgba(54, 82, 129, 0.08);
  border: none;
}

.menu-content {
  padding: 20px 0;
}

.categories-box {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  margin-bottom: 15px;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    padding: 8px 0px 8px 10px;
    width: 100%;
    z-index: 100;
    background-color: rgba($color: #fff, $alpha: 1);
  }

  .chip {
    padding: 5px 10px;
    border-radius: 10px;
    background-color: rgba(210, 210, 210, 0.403);
    margin-right: 10px;
    color: black;
    white-space: nowrap;
    cursor: pointer;
  }
}

.search-box {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(54, 82, 129, 0.1);
  border-radius: 14px;
  margin-bottom: 10px;

  label {
    padding: 14px;
    cursor: pointer;
  }

  input {
    margin: 0;
    padding: 15px 16px 15px 0;
    min-height: 0;
    width: 100%;
    font-size: $fontsize16;
    font-weight: 400;
    border: none !important;
    outline: none !important;

    &::placeholder {
      color: #abadb4;
    }
  }
}

.menu {
}

.menu-item {
  padding: 20px 16px;
  margin: 0px -16px;
  display: flex;
  position: relative;
  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
  }

  + .menu-item {
    border-top: 1px solid $border-line;
  }

  .unavailable {
    background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    margin-top: -20px;
    margin-left: -16px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 28px;
  }

  .item-img {
    width: 72px;
    height: 92px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .no-image-spacer {
    height: 76px;
  }

  .item-caption {
    width: calc(100% - 100px);
    padding-inline-start: 12px;
    min-height: 100px;
    position: relative;

    .item-name {
      font-size: $fontsize16;
      font-weight: 500;
      margin: 0 0 4px;
    }

    .item-desc {
      color: $neutral;
      font-size: $fontsize12;
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;

      -webkit-box-orient: vertical;
    }

    .item-price {
      padding-inline-start: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .amount {
        color: $primary;
        font-size: $fontsize14;
        font-weight: 500;

        .pre-order {
          margin-inline-start: 5px;
          background-color: $primary;
          color: $white;
          padding: 4px;
          border-radius: 5px;
        }

        .old {
          color: $neutral-2;
          margin-right: 10px;
          text-decoration: line-through;
        }
      }

      .order {
        display: flex;
        align-items: center;

        .status {
          margin-right: 8px;
        }
      }
    }
  }

  .no-description {
    min-height: 50px;
  }

  &.selected {
    .item-img {
      &::before {
        content: '\f00c';
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        display: flex;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        background: $white;
        border-radius: 2px;
        position: absolute;
        bottom: 4px;
        right: 4px;
        z-index: 1;
      }
    }
  }

  .btn-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    position: absolute;
    left: 100%;
    top: 20px;
    bottom: 20px;
    border-radius: 10px;
    background: #fb7080;
    z-index: 5;
  }

  &.active {
    transform: translateX(-62px);
  }
}

.menu-type-container {
  margin: 0 -16px;
  padding: 16px;
  border-top: 1px solid $border-line;
  border-bottom: 1px solid $border-line;

  .menu-type {
    @extend .headline-2;
    margin-bottom: 0px;
  }

  .menu-type-description {
    margin-top: 0.75rem;
    color: $neutral;
    font-size: $fontsize12;
  }
}

.coin-bonus {
  display: flex;
  align-items: center;
  padding: 5px 8px 5px 5px;
  color: $white;
  background: $primary;
  font-size: $fontsize13;
  line-height: 1;
  border-radius: 100px;

  span {
    margin-left: 4px;
    margin-top: -2px;
  }

  &.md {
    font-size: $fontsize14;
  }

  &.light {
    color: $primary;
    background: $white;
  }
}

.status {
  font-size: $fontsize13;
  border-radius: 100px;
  padding: 1px 8px;

  &.out-of-order {
    color: $primary;
    background: rgba(36, 42, 137, 0.1);
  }
}

.btn-checkout {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;
  background: $primary;
  border-radius: 10px;
  padding: 8px 16px 8px 8px;
  box-shadow: 0px 9.42px 17px rgba(35, 41, 136, 0.33);
  text-decoration: none;
  position: fixed;
  bottom: 30px;
  left: 16px;
  right: 16px;
  z-index: 800;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.pos2 {
    bottom: 30px;
  }

  .order-info {
    display: flex;
    align-items: center;

    .amount {
      width: 34px;
      height: 34px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      background: $white;
      font-size: $fontsize16;
      font-weight: 700;
      margin-inline-end: 10px;
    }

    &.confirm {
      min-height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      .text {
        font-weight: 700;
        font-size: $fontsize16;
      }
    }

    .text {
      font-size: $fontsize14;
      font-weight: 500;
    }
  }

  .order-price {
    font-size: $fontsize20;
    font-weight: 500;
    display: flex;
    align-items: center;

    .coin-bonus {
      margin-right: 10px;
    }
  }
}

.alcohol-popup {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 900;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  justify-content: center;
  align-items: center;

  &.show {
    display: block;
  }

  .confirm {
    color: $white;
    background: $primary;
    border-radius: 10px;
    padding: 18px 8px;
    box-shadow: 0px 9.42px 17px rgba(35, 41, 136, 0.33);
    border-width: 0px;
    width: 100%;
    text-decoration: none;
  }

  .cancel {
    color: $white;
    background: $neutral-2;
    margin-top: 15px;
    border-radius: 10px;
    padding: 18px 8px;
    box-shadow: 0px 9.42px 17px rgba(35, 41, 136, 0.33);
    border-width: 0px;
    width: 100%;
    text-decoration: none;
  }

  .popup-box {
    background-color: white;
    width: 85%;
    border-radius: 5px;
    padding: 15px;
    margin: auto;
    margin-top: 15%;
  }
}
