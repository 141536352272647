.date-override {
  .date-p {
    width: calc(100% - 20px);
    border-radius: 5px;
    border-color: #e3e3e3;
    padding: 10px;
  }

  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      width: 100%;
      z-index: 1000;
      .react-datepicker--time-only {
        width: 100%;
        .react-datepicker__time-container {
          width: 100%;
          .react-datepicker__time-box {
            width: 100%;
          }

          .react-datepicker__time-list {
            max-height: 300px;

            .react-datepicker__time-list-item--disabled {
              display: none;
            }
          }
        }
      }
    }
  }
}
