.order-container {
  padding: 0px 16px;

  margin-bottom: 100px;

  .order-list {
    .menu-item {
      cursor: default;
      .item-img {
        width: 100px;
      }

      &.surcharge {
        height: 45px;

        .item-caption {
          min-height: 30px;
        }
      }
    }
  }
}

.order-shipping {
  border-top: 1px solid rgba(54, 82, 129, 0.08);
  padding: 20px 16px 0;
  margin: 0px -16px;
  & > * {
    margin-bottom: 5px;
  }
}

.alcohol-warning {
  color: white;
  background-color: #fb867d;
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 15px;
  font-weight: 700;
}

.shipping-options {
}

.order-note {
  width: calc(100% - 32px);
  padding: 16px;
  resize: none;
  font-size: $fontsize14;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.recommendations {
  padding: 16px;
  margin: 0px -16px;

  .recommend-list {
    margin-bottom: 80px;
  }
}

.recommend-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  + .recommend-item {
    border-top: 1px solid rgba(54, 82, 129, 0.08);
  }

  .item-info {
    display: flex;
    align-items: center;
    padding: 16px 0;

    .item-img {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-caption {
      padding-left: 16px;

      .item-name {
        font-size: $fontsize14;
        font-weight: 500;
        margin: 0 0 6px;
      }

      .item-price {
        font-size: $fontsize16;
        font-weight: 600;
        color: $primary;
      }
    }
  }
}
