$primary: #fb867d;
$error: #fb7080;
$black: #000;
$white: #fff;
$main: #263238;
$neutral: #7e8087;
$neutral-2: #abadb4;
$border-line: rgba(54, 82, 129, 0.08);

$fontsize11: 0.6875rem;
$fontsize12: 0.75rem;
$fontsize13: 0.8125rem;
$fontsize14: 0.875rem;
$fontsize15: 0.9375rem;
$fontsize16: 1rem;
$fontsize18: 1.125rem;
$fontsize20: 1.25rem;
$fontsize24: 1.5rem;

.orderstatus-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .orderstatus-modal {
    position: relative;
    max-width: 60vw;
    min-width: 20vw;
    min-height: 20vh;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #fefefe;
    border-radius: 10px;
    padding: 30px;

    .orderstatus-modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      opacity: 0.8;

      & > img {
        width: 15px;
        height: auto;
      }
    }
  }
}

.checkbox-mynt[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: 2px solid #413fad;
  background-color: #413fad;
}

.checkbox {
  margin-bottom: 20px;

  input[type='checkbox'] {
    display: none;

    + label {
      padding-inline-start: 36px;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid $neutral;
        box-sizing: border-box;
        position: absolute;
        top: -1px;
        inset-inline-start: 0;
      }

      &::after {
        content: '\f00c';
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        color: $white;
        background: $primary;
        position: absolute;
        top: -1px;
        inset-inline-start: 0;

        opacity: 0;
        transition: all 0.5s ease;
      }
    }

    &:checked {
      + label {
        color: $main;
        font-size: $fontsize14;

        &::after {
          opacity: 1;
        }
      }
    }
  }
}
