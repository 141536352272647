.updatemodal-background {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.373);
  display: flex;
  align-items: center;
  justify-content: center;

  .update-modal {
    background-color: rgb(236, 236, 236);
    min-width: 70vw;
    max-width: 90vw;
    max-height: 80vh;
    overflow-y: scroll;

    padding: 15px;
    border-radius: 15px;

    display: flex;

    flex-direction: column;

    .language-option {
      cursor: pointer;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 64px;
        margin-inline-end: 15px;
      }
    }

    button {
      background-color: #fb867d;
      padding: 10px;
      color: #fff;
      font-weight: 700;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .btn {
      background-color: #fb867d;
      padding: 10px;
      color: #fff;
      font-weight: 700;
      border: none;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
    }

    .btn-close {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 100px;
      color: #263238;
      background: rgba(196, 196, 196, 0.75);
      position: sticky;
      position: -webkit-sticky;
      margin-bottom: -36px;
      inset-inline-start: 100%;
      z-index: 10;
    }

    h3 {
      margin: 0px;
    }

    button:hover {
      background-color: #ff928a;
    }
  }
}
