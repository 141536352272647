header.header-home {
  position: relative;
  height: 250px;
  .btn-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50px;
    left: 16px;
    color: $white;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(6px);
    border-radius: 6px;
    font-size: $fontsize20;
  }
  .banner {
    img {
      width: 100%;
      height: 275px;
      object-fit: cover;
    }
  }
}

header.header-page {
  padding: 20px 16px 0;
  // padding: calc(env(safe-area-inset-top) + 20px) calc(env(safe-area-inset-right) + 16px) calc(env(safe-area-inset-bottom) + 0px) calc(env(safe-area-inset-left) + 16px);
  display: flex;
  align-items: center;
  .btn-back {
    font-size: $fontsize20;
    color: $main;
    margin-right: 16px;
  }
  .page-name {
    margin: 0;
  }
}
