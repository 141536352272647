@use '../../Styles/components/variables.scss' as *;

.text-field-wrapper {
  .text-field {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    outline: none !important;

    width: calc(100% - 32px);
    padding: 16px;
    resize: none;
    font-size: $fontsize14;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;

    &::placeholder {
      color: #abadb4;
    }
  }

  .text-field-error-label {
    color: $error;
  }

  .error {
    border-color: $error;
  }
}
