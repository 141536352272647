.text-field {
  width: calc(100% - 32px);
  padding: 16px;
  resize: none;
  font-size: $fontsize14;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  outline: none !important;

  &::placeholder {
    color: #abadb4;
  }
}

.text-field-error {
  border: 1px solid $primary;
}
