@import './components/variables.scss';

@import './components/typography.scss';
@import './components/header.scss';
@import './components/form.scss';
@import './components/popup.scss';
@import './components/loading.scss';
@import './components/updatemodal.scss';
@import './components/textfield.scss';

@import './views/menu.scss';
@import './views/order.scss';
@import './views/payment.scss';
@import './views/coupon.scss';

@media only screen and (min-width: 800px) {
  body:not(.for-order-status) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('./MyntSplash.svg');
    background-position: center;
    height: 100vh;
    overflow: clip;

    #root {
      position: relative;
      z-index: 100000;
      // height: unset !important;
      border: 10px solid rgb(76, 76, 76);
      border-radius: 5px;
      background-color: #fff;
      width: 400px;
      height: 90vh !important;
    }

    .categories-box.fixed {
      display: none;
    }

    .page-layout {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }

    .popup-overlay {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% - 50px);

      .popup-container {
        height: 100%;
      }
    }

    .btn-checkout {
      position: absolute;
    }
  }

  .btn-continue {
    position: absolute;
    bottom: 30px;
  }
}

//swipable button
.ReactSwipeButton {
  float: left;
  width: 100%;
  height: 50px;
  position: relative;
}
.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}
.rsbContainer {
  float: left;
  width: 100%;
  height: 100%;
  background: #157946;
  border-radius: 50px;
  position: relative;
  /* box-shadow: inset 1px 1px 4px rgba(0,0,0,0.1); */
  overflow: hidden;
}
.rsbContainerUnlocked {
  width: 50% !important;
  margin-left: 25%;
  transition: 0.5s;
  cursor: default;
}
.rsbContainerUnlocked .rsbcSlider {
  left: 100% !important;
  cursor: default;
  pointer-events: none;
}
.rsbContainerUnlocked .rsbcSliderArrow {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbContainerUnlocked .rsbcSliderCircle {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbcSlider {
  float: left;
  width: 100%;
  position: absolute;
  height: 50px;
  top: 0;
  left: 50px;
  margin-left: -100%;
  background: #51ad7e;
  border-radius: 25px;
  z-index: 100;
  /* box-shadow: 1px 1px 5px rgba(0,0,0,0.3); */
  cursor: pointer;
}
.rsbcSliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 50px;
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  color: #fff;
  font-size: 13px;
}
.rsbcSliderArrow {
  float: left;
  position: absolute;
  transform: rotate(45deg);
  border: 2px solid #fff;
  height: 8px;
  width: 8px;
  top: 50%;
  right: 22px;
  margin-top: -6px;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform-origin: center;
  z-index: 10;
}
.rsbcSliderCircle {
  position: absolute;
  right: 0;
  background: #51ad7e;
  /* border: 5px solid #157946; */
  top: 0;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}
.rsbcText {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  font-size: 13px;
  color: #aaa;
}

.hidden {
  display: none;
}
