.headline-1 {
  font-size: $fontsize20;
  line-height: 1.3;
  font-weight: 600;
  color: $main;
  margin: 0 0 1rem;
}
.headline-2 {
  font-size: $fontsize18;
  font-weight: 600;
  margin: 0 0 1rem;
}
.headline-3 {
  font-size: $fontsize16;
  font-weight: 600;
  margin: 0px;
}

.description {
  font-size: $fontsize13;
  color: #7e8087;
}

.readmore {
  font-size: $fontsize13;
  color: #fb867d;
}

.text-error {
  color: #fb867d;
}
