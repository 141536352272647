.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background: rgba($black, 0.4);
  padding-top: 50px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &.show {
    opacity: 1;
    visibility: visible;

    .popup-container {
      // transition-delay: 0.2s;
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  .popup-container {
    .ios-margin {
      margin-bottom: 60px;
    }

    border-radius: 25px 25px 0 0;
    background: $white;
    padding: 0 16px;
    height: calc(100vh - 50px);
    overflow: auto;
    position: relative;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: all 0.3s ease;

    .btn-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      border-radius: 100px;
      color: $main;
      background: rgba(196, 196, 196, 0.75);
      position: sticky;
      position: -webkit-sticky;
      margin-bottom: -36px;
      top: 16px;
      left: 100%;
      z-index: 10;
      cursor: pointer;
    }

    .item-props {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: calc(100% - 50px);
      margin: 16px 0;

      .prop {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          width: 34px;
          height: 34px;
          border-radius: 100px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .text {
          margin-top: 4px;
          font-size: $fontsize11;
        }
      }
    }

    .item-img {
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 30px;
      margin-left: -15px;
      margin-right: -15px;
      margin-top: -50px;

      img {
        width: 100%;
        max-height: 250px;
        object-fit: cover;
      }
    }

    .item-name {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 1rem;

      .name {
        flex: auto;
        font-weight: 700;
        margin-bottom: 0;
      }

      .coin-bonus {
        margin-top: 2px;
      }
    }

    .item-price {
      color: $primary;
      font-size: $fontsize20;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .item-desc {
      color: $neutral;
    }

    .pre-order {
      margin-top: 15px;
      background-color: $primary;
      color: $white;
      padding: 6px;
      border-radius: 5px;
    }

    .item-options {
      border-top: 1px solid rgba(54, 82, 129, 0.08);
      padding: 20px 16px 0;
      margin: 20px -16px 0;

      .option-title {
        font-size: $fontsize20;
        margin-bottom: 16px;
      }
    }

    .popup-action {
      position: sticky;
      position: -webkit-sticky;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      padding: 25px 16px 30px;

      .btn-addtocart {
        flex: auto;
        margin-inline-start: 16px;
      }
    }

    .ios {
      bottom: 60px;
    }
  }
}

.btn-addtocart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: $white;
  background: $primary;
  line-height: 20px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 9.42px 17px rgba(35, 41, 136, 0.33);
  cursor: pointer;
}

.info-box {
  color: white;
  background-color: #fb867d;
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 15px;
  font-weight: 700;
  display: flex;

  .number {
    margin-left: 5px;
    margin-right: 5px;
    width: 24px;
    height: 24px;
    color: #fb867d;
    background-color: white;
    text-align: center;
    border-radius: 15px;
    line-height: 1.7;
  }
}
