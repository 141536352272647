.order-status-view {
  position: relative;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .version {
    font-size: 10px;
    color: black;
  }

  & * {
    box-sizing: border-box;
  }

  .time {
    position: absolute;
    bottom: 0;
    right: 5px;
    color: #000;
  }
}

.order-status-title {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  text-align: center;
  background-color: #fcfcfc;

  & > h1 {
    margin: 0;
  }
  color: black;
}

.order-status-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;

  .control-bar {
    position: absolute;
    right: 10px;
    top: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin: 5px;
      cursor: pointer;
      padding: 15px;
      background-color: #fff;
      border-radius: 100%;

      & > img {
        width: 42px;
        height: auto;
      }
    }
  }

  .col {
    background-color: black;
    color: white;
    width: 50%;
    padding-top: 10px;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .orders-box {
      padding-top: 20px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;

      .card-consumer {
        width: calc(20% - 50px);
        font-size: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: black;
        margin: 25px;
        border-radius: 15px;
        transition: all 2s;
        animation: appear 1.5s;

        @keyframes appear {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        h4 {
          margin: 0;
        }

        &.done {
          color: #2e7d32;
        }

        // New syntax, not supported by old browsers
        container-type: size;
        h4 {
          font-size: 40cqw !important;
        }

        // Manual font size for old browsers
        &.in-row-5 {
          $margin: 15px;
          $side-length: calc(50vw / 5 - $margin * 2);

          width: $side-length;
          font-size: 50px;
          height: $side-length;
        }
        &.in-row-4 {
          $margin: 20px;
          $side-length: calc(50vw / 4 - $margin * 2);

          margin: $margin;
          width: $side-length;
          font-size: 70px;
          height: $side-length;
        }
        &.in-row-3 {
          $margin: 20px;
          $side-length: calc(50vw / 3 - $margin * 2);

          margin: $margin;
          width: $side-length;
          font-size: 90px;
          height: $side-length;
        }
        &.in-row-2 {
          $margin: 30px;
          $side-length: calc(50vw / 2 - $margin * 2 - 1px);

          margin: $margin;
          width: $side-length;
          font-size: 180px;
          height: $side-length;
        }
      }
    }
    .listElem {
      width: 100%;
      font-size: 24px;
      p {
        margin: 0px;
        text-transform: uppercase;
      }
      h4 {
        margin: 2px 0px;
      }
      padding: 4px;
      margin: 8px;
    }
  }

  .title {
    text-align: center;
    font-size: 3rem;
    margin: 15px;
  }

  .done {
    background-color: #d1ffca;
    color: black;
    height: 100%;

    .card {
      .extras {
        display: none;
      }
      .footer {
        display: none;
      }
    }
  }

  .hidden {
    display: none;
  }

  .card {
    margin: 1%;
    width: 46%;
    padding: 10px;
    border-color: black;
    border-width: 1px;
    border-radius: 2px;

    border-style: solid;

    font-size: 1.5rem;
    line-height: 1.8rem;
    word-wrap: break-word;

    background-color: rgba(255, 255, 255, 0.14);

    &.font-small {
      p {
        font-size: 1.4rem;
        margin-top: 4px;
        margin-bottom: 4px;
      }
      .options {
        font-size: 1.6rem;

        li {
          margin: 4px 0;
        }
        margin-top: 4px;
      }
      .extras {
        font-size: 1rem;
        margin-bottom: 8px;

        li {
          margin: 2px 0;
        }
      }
    }
    &.font-medium {
      p {
        font-size: 2rem;
        margin-top: 8px;
        margin-bottom: 8px;
      }
      .options {
        font-size: 1.8rem;

        li {
          margin: 8px 0;
        }

        margin-top: 10px;
      }
      .extras {
        font-size: 1.4rem;
        margin-bottom: 15px;

        li {
          margin: 5px 0;
        }
      }
    }
    &.font-large {
      p {
        font-size: 2.2rem;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .options {
        font-size: 2.2rem;

        li {
          margin: 15px 0;
        }

        margin-bottom: 10px;
      }
      .extras {
        font-size: 1.6rem;
        margin-bottom: 20px;

        li {
          margin: 10px 0;
        }
      }
    }

    .order-card-menu-item {
      &.ready {
        color: rgb(72, 188, 18);
      }
    }

    h4 {
      margin: 0px;
      border-bottom: 2px solid;
    }

    p {
      text-transform: uppercase;
      margin: 0px;
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 2rem;
    }

    .options {
      font-size: 1.8rem;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .extras {
      font-size: 1.2rem;
    }
    .extraList {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .extraList {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .footer {
      margin-top: 10px;
      text-align: center;
      border-width: 0px;
      border-top-width: 1px;
      border-color: black;
      border-style: solid;
      font-size: 1.2rem;

      &.no-border {
        border: none;

        .counter {
          font-size: 1rem;
        }
      }

      .counter {
        margin-top: 5px;
      }
    }

    &.hide {
      display: none;
    }
  }

  .yellow {
    background-color: yellow;
    color: black;
  }

  .red {
    background-color: red;
    color: white;
  }

  .can-hide-button {
    position: absolute;
    bottom: 15px;
    right: 15px;
    padding: 15px;
    border-radius: 15px;
  }

  .col-25 {
    width: 25%;

    .card {
      width: 98%;
      margin: 2%;
    }
  }

  .col-75 {
    width: 75%;

    .card {
      width: calc(50% - 2%);
    }
  }

  .col-100 {
    width: 100%;
  }
}

#root {
  // height: 100vh;
  height: 100%;
  width: 100%;
}

.offlineOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  color: #fff;
  font-size: 1.5rem;
}

.errorMessage {
  max-width: 80%;
  text-align: center;
}
