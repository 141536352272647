.all-day-receipt {
  display: flex;
  flex-direction: column;
  background-color: rgb(217, 217, 217);
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;

  .all-day-receipt-row {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
  }
}
