.mynt-container {
  display: flex;
  flex-direction: column;

  .header-wrapper {
    background-color: #409b9a;
    height: 40vh;
  }

  .header {
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-content: center;

    .carousel {
      overflow-x: scroll;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      width: 300%;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      scroll-padding: 0;
      scroll-margin: 0;
      scroll-margin-left: 0;
      scroll-margin-right: 0;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .img-box {
        height: 100%;
        width: 100vw;
        display: flex;
        justify-content: center;
        scroll-padding: 0px;
        scroll-margin: 0px;
        scroll-margin-left: 0;
        scroll-margin-right: 0;
        scroll-snap-align: center;
        scroll-snap-stop: always;

        img {
          height: 100%;
          scroll-snap-align: start;
          -webkit-filter: drop-shadow(5px 5px 5px #222);
          filter: drop-shadow(5px 5px 5px #222);
        }
      }
    }

    .carousel::-webkit-scrollbar {
      display: none;
    }
  }

  .sub-header {
    background-color: #409b9a;
    color: #fff;
    text-align: center;

    p {
      margin: 0px;
    }
  }

  .body {
    height: 35vh;
    background-color: #fb867d;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .highlight {
        background-color: white;
        color: #fb867d;
        max-width: 80%;
        padding: 10px;
        border-radius: 15px;
        font-weight: 700;
        font-size: 18px;
      }

      .error {
        p {
          margin-top: 0px;
        }
      }

      .explainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
          font-weight: 300;
        }

        p {
          max-width: 80%;
          font-size: 16px;
        }
      }
    }
  }

  .footer {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
