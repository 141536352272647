.radio {
  input[type='radio'],
  input[type='checkbox'] {
    display: none;
    + label {
      padding-left: 36px;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        border: 1px solid $neutral;
        box-sizing: border-box;
        position: absolute;
        top: -1px;
        left: 0;
      }

      &::after {
        content: '\f00c';
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        color: $white;
        background: $primary;
        position: absolute;
        top: -1px;
        left: 0;
        opacity: 0;
        transition: all 0.5s ease;
      }
    }

    &:checked {
      + label {
        color: $main;
        font-size: $fontsize14;

        &::after {
          opacity: 1;
        }
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.checkbox {
  margin-bottom: 20px;

  input[type='checkbox'] {
    display: none;

    + label {
      padding-inline-start: 36px;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid $neutral;
        box-sizing: border-box;
        position: absolute;
        top: -1px;
        inset-inline-start: 0;
      }

      &::after {
        content: '\f00c';
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        color: $white;
        background: $primary;
        position: absolute;
        top: -1px;
        inset-inline-start: 0;

        opacity: 0;
        transition: all 0.5s ease;
      }
    }

    &:checked {
      + label {
        color: $main;
        font-size: $fontsize14;

        &::after {
          opacity: 1;
        }
      }
    }
  }
}

.radio-list {
  display: flex;
  flex-wrap: wrap;

  .radio {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .checkbox {
    width: 100%;

    &.disabled {
      input[type='checkbox']:not(:checked) + label::before {
        background-color: rgb(234 234 234);
      }
    }
  }

  [type='checkbox'].disabled:not(:checked) {
    border: 2px solid #413fad !important;
    background-color: #413fad !important;
  }
}

.btn-adjust {
  cursor: pointer;
}

.input-amount {
  display: flex;
  align-items: center;
  background: #e9e9f3;
  border-radius: 10px;
  padding: 0px 10px;

  .btn-adjust {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    background: $white;
    border-radius: 100px;
  }

  input {
    cursor: pointer;

    background: none;
    color: $primary;
    font-size: $fontsize14;
    font-weight: 600;
    border: none !important;
    outline: none !important;
    width: 38px;
    padding: 17px 10px;
    text-align: center;
  }
}

.add-input-amount {
  position: relative;

  .btn-add {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d8d8d8;
    color: $main;
    background: $white;
    font-size: $fontsize14;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 6px;
  }
}

.input-amount {
  padding: 0 6px;
  border-radius: 6px;

  .btn-adjust {
    width: 18px;
    height: 18px;
    font-size: $fontsize12;
  }

  input {
    width: 22px;
    padding: 7px 10px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  outline: none !important;

  &::placeholder {
    color: #abadb4;
  }
}

.menu-item {
  .item-image {
    min-width: 69px;
  }

  .input-amount {
    height: 30px;
    margin-top: auto;
    margin-left: auto;

    .remove {
      background: #fb7080;
      padding: 3px;
      border-radius: 15px;
      margin-right: 15px;
      display: flex;
    }

    .btn-adjust {
      opacity: 0;
      visibility: hidden;
      display: none;
      transition: all 0.5s;
    }

    &.show {
      .btn-adjust {
        opacity: 1;
        visibility: visible;
        display: flex;
      }
    }
  }
}
